<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) Determine the
        <stemble-latex content="$\textbf{molar solubility}$" />
        of cadmium hydroxide,
        <chemical-latex content="Cd(OH)2" />. The
        <stemble-latex content="$\text{K}_{\text{sp}}$" />
        of cadmium hydroxide at
        <stemble-latex content="$25^\circ\text{C}$" />
        is
        <number-value :value="Ksp" unit="\text{.}" />
        Assume that there is no other hydroxide present in the solution.
      </p>

      <calculation-input
        v-model="inputs.S"
        class="mb-6"
        :prepend-text="'$\\text{S:}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Calculate the pH of a saturated solution of
        <chemical-latex content="Cd(OH)2" />
        at
        <stemble-latex content="$25^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-6"
        :prepend-text="'$\\text{pH:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question305b',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        S: null,
        pH: null,
      },
    };
  },
  computed: {
    Ksp() {
      return this.taskState.getValueBySymbol('Ksp').content;
    },
  },
};
</script>
